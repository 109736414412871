interface Window{
    zidxBase:ZIDXBase;
}
class ZIDXPaginationOptions{
    id:string="";
    count:number=0;
    perpage:number=30;
    offset:number=0;
    loadFunction:Function=function(){};

}
class ZIDXPagination{
    options:ZIDXPaginationOptions;
    constructor(options:ZIDXPaginationOptions) {
        let self = this;
        if (options.id == "") {
            throw("zidxPagination: options.id is required");
        }
        this.options=options;
        if (typeof options.loadFunction != "function") {
            throw("zidxPagination: options.loadFunction is required and must be a function");
        }
        this.render();
    }
    updatePerPage (perpage:number) {
        this.options.perpage = perpage;
        this.render();
    };
    updateCount (count:number) {
        this.options.count = count;
        this.render();
    };
    updateOffset (offset:number) {
        this.options.offset = offset;
        this.render();
    };
    render(){
        this.drawNavLinks(this.options.id, this.options.count, this.options.offset, this.options.perpage);
    };
    runLoad(){
        this.options.loadFunction(this.options);
    }
    drawNavLinks(id:string, count:number, curOffset:number, perPage:number){
        let arrR=new Array();
        let linkCount=5;
        let firstLinkCount=Math.floor((linkCount-1)/2);
        let beforeLinkCount=Math.min(firstLinkCount, this.options.offset/this.options.perpage);

        let pageCount=Math.ceil(count/perPage);
        let lastLinkCount=(linkCount-1)-firstLinkCount;

        let firstOffset=curOffset-(beforeLinkCount*perPage);

        let arrBind=[];
        if(firstOffset!=curOffset) {
            arrR.push('<a href="#" class="zidxPagination-previousLink">Previous<\/a>');

            arrBind.push({
                "selector": ".zidxPagination-previousLink",
                "offset": curOffset - perPage
            });
        }else{
            arrR.push('<span class="zidxPagination-previousLinkSpan"><\/span>');
        }
        for(let i=0;i<linkCount;i++){
            let coff=((i*perPage)+firstOffset);
            let clabel=(coff/perPage)+1;
            if(clabel <= pageCount){
                if(clabel == pageCount && coff+perPage == curOffset){
                    arrR.push('<span class="zidxPagination-search-nav-t">'+clabel+'</span>');
                }else if(coff == curOffset){
                    arrR.push('<span class="zidxPagination-search-nav-t">'+clabel+'</span>');
                }else{
                    arrR.push('<a href="#" class="zidxPagination-link'+clabel+'">'+clabel+'</a>');
                    arrBind.push({
                        "selector":'.zidxPagination-link'+clabel,
                        "offset": coff
                    });
                }
            }else{
                arrR.push('<span>&nbsp;</span>');
            }
        }
        if(pageCount > 1 && curOffset+perPage < count){
            arrR.push('<a href="#" class="zidxPagination-nextLink">Next<\/a>');
            arrBind.push({
                "selector":".zidxPagination-nextLink",
                "offset":curOffset+perPage
            });
        }else{
            arrR.push('<span class="zidxPagination-nextLinkSpan"><\/span>');
        }
        let self=this;
        let r='<div style="width:100%; float:left" class="zidxPagination-container">'+arrR.join("")+'</div>';
        ZIDX.$("#"+id).html(r);
        for(let i=0;i<arrBind.length;i++){
            ZIDX.$(arrBind[i].selector, "#"+id).unbind("click");
            let c=arrBind[i];
            ZIDX.$(arrBind[i].selector, "#"+id).attr("data-offset", c.offset);
            ZIDX.$(arrBind[i].selector, "#"+id).bind("click", function(this:any){
                let offset=parseInt(ZIDX.$(this).attr("data-offset"));
                self.options.offset=offset;
                self.runLoad();
                return false;
            });
        }
    }
}